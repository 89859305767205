<template>
  <b-col
    lg="8"
    class="d-none d-lg-flex align-items-center p-5 bg-dark"
  >
    <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
      <b-img
        class="fade-in-image"
        fluid
        :src="imgUrl"
        alt="Login V2"
      />
    </div>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      sideImg: require('@/assets/images/logo/new_logo.png'),
    }
  },
  computed: {
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/logo/logo.png')
      //   return this.sideImg
      // }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/logo/new_logo.png')
      return this.sideImg
    },
  },
}
</script>

<style>

</style>